<template>
  <div v-if="!droppable" class="form-group">
    <label v-if="label" class="form-label" :for="id">{{ label }}</label>
    <input
      v-show="!hidden"
      :id="id"
      ref="file"
      class="form-control"
      type="file"
      :name="name"
      :disabled="isLoading"
      @input.prevent="processUpload"
    />
  </div>
  <div
    v-else
    class="form-group form-group-droppable p-3 border rounded text-center align-items-center"
    :class="{ loading: isLoading }"
    @dragenter.prevent
    @dragover.prevent
    @dragleave="isDropping = false"
    @drop.prevent="processDroppedUpload"
    @click="openFileDialog()"
  >
    <i class="mdi mdi-upload fs-xl"></i>
    <div class="flex-fill">
      <input
        v-show="false"
        :id="id"
        ref="file"
        class="form-control"
        type="file"
        :name="name"
        :disabled="isLoading"
        @input.prevent="processUpload"
      />
      <slot>
        <span v-if="!isLoading">Click to upload or drop files here</span>
        <span v-else>
          <loader
            v-if="isLoading && !isSlowUpload"
            :active="true"
            :inline="true"
            :small="true"
          ></loader>
          <div v-else class="progress" style="height: 10px">
            <div
              class="progress-bar"
              role="progressbar"
              :style="{ width: uploadPercent + '%' }"
              :aria-valuenow="uploadPercent"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </span>
      </slot>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import axios from "axios";
import Loader from "@/components/loader.vue";

const props = defineProps({
  label: {
    type: String,
    required: false,
  },
  id: {
    type: String,
    default: () => Math.random().toString(36).substring(2, 7),
  },
  name: {
    type: String,
    required: false,
  },
  urlUpload: {
    type: String,
    required: true,
  },
  urlComplete: {
    type: String,
    required: true,
  },
  hidden: {
    type: Boolean,
    default: false,
  },
  droppable: {
    type: Boolean,
    default: false,
  },
});

const isLoading = ref(false);
const lastUploadEvent = ref(null);
const uploadedStartAt = ref(null);
const currentTime = ref(Date.now());
const file = ref(null);
let timeInterval = null;

const emit = defineEmits(["uploaded"]);

onMounted(() => {
  timeInterval = setInterval(() => {
    currentTime.value = Date.now();
  }, 500);
});

onBeforeUnmount(() => {
  if (timeInterval) {
    clearInterval(timeInterval);
  }
});

const processUpload = (e) => {
  if (!e.target?.files?.length) {
    return;
  }
  beginUpload(e.target.files);
};

const processDroppedUpload = (e) => {
  const dt = e.dataTransfer;
  if (!dt) {
    return;
  }

  const files = dt.files;
  if (!isLoading.value) {
    beginUpload(files);
  }
};

const beginUpload = async (files) => {
  isLoading.value = true;
  uploadedStartAt.value = null;
  lastUploadEvent.value = null;

  const file = files[0];

  let uploadDetails;
  try {
    const response = await axios.post(props.urlUpload, {
      name: file.name,
      size: file.size,
    });
    uploadDetails = response.data;
  } catch (error) {
    reset();
    Swal.fire({
      title: "Error",
      text: "An unknown error occurred when attempting to start the upload",
      icon: "error",
    });
    return;
  }

  const payload = new FormData();
  for (const [key, value] of Object.entries(uploadDetails.inputs)) {
    payload.append(key, value);
  }
  payload.append("key", uploadDetails.destination);
  payload.append("file", file);

  uploadedStartAt.value = Date.now();

  try {
    await axios.post(uploadDetails.action, payload, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (ev) => {
        lastUploadEvent.value = ev;
      },
    });
  } catch (error) {
    reset();
    Swal.fire({
      title: "Error",
      text: "An unknown error occurred when attempting to upload the file",
      icon: "error",
    });
    return;
  }

  try {
    const { data } = await axios.post(props.urlComplete, {
      internalSignature: uploadDetails.internalSignature,
    });
    emit("uploaded", data);
  } catch (error) {
    Swal.fire({
      title: "Error",
      text: "An unknown error occurred when attempting to complete the upload",
      icon: "error",
    });
    return;
  } finally {
    reset();
  }
};

const openFileDialog = () => {
  if (!file.value) {
    return;
  }
  file.value.click();
};

const reset = () => {
  file.value.value = null;
  isLoading.value = false;
  uploadedStartAt.value = null;
  lastUploadEvent.value = null;
};

const isSlowUpload = computed(
  () => currentTime.value - uploadedStartAt.value > 2000
);

const uploadPercent = computed(() => {
  if (!lastUploadEvent.value) {
    return 0;
  }
  return Math.round(
    (lastUploadEvent.value.loaded / lastUploadEvent.value.total) * 100
  );
});
</script>

<style lang="scss" scoped>
.form-group-droppable {
  transition: all 0.1s ease-in-out;
  opacity: 1;

  &.loading {
    opacity: 0.75;
  }
  &:not(.loading) {
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }
}
</style>
