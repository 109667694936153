<template>
  <div @click.self="closeModal">
    <div
      id="modal-block-extra-large"
      class="modal show"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
      style="display: block"
    >
      <div class="modal-dialog" :class="modalClasses" role="document">
        <div class="modal-content">
          <div class="block block-rounded block-transparent mb-0">
            <div class="block-header block-header-default">
              <h3 class="block-title">{{ title }}</h3>
              <div class="block-options">
                <button
                  type="button"
                  class="btn-block-option"
                  aria-label="Close"
                  @click="closeModal"
                >
                  <i class="fa fa-fw fa-times"></i>
                </button>
              </div>
            </div>
            <div class="block-content fs-sm">
              <slot></slot>
            </div>
          </div>
          <div class="block-content block-content-full text-end bg-body">
            <button
              type="button"
              class="btn btn-sm btn-alt-secondary me-1"
              @click="closeModal"
            >
              Close
            </button>
            <button type="button" class="btn btn-sm btn-primary" @click="save">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show" @click="closeModal"></div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  modalClasses: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["close", "save"]);

function closeModal() {
  emit("close");
}

function save() {
  emit("save");
}
</script>
