<template>
  <div
    class="loader-container"
    :class="{ fill: isFilled, active: isActive, 'd-inline-block': inline }"
  >
    <div
      v-if="isActive"
      class="spinner-border text-info"
      :class="{
        'spinner-border-sm': isSmall,
      }"
      role="status"
    >
      <span class="visually-hidden"><slot>Loading...</slot></span>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  fill: {
    type: Boolean,
    default: false,
  },
  inline: {
    type: Boolean,
    default: false,
  },
});

const isActive = ref(props.active || false);
const isSmall = ref(props.small || false);
const isFilled = ref(props.fill || false);
</script>

<style lang="scss" scoped>
.loader-container {
  &.fill {
    display: none;

    &.active {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 5000;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: rgba(white, 0.75);
    }
  }
}
</style>
