<template>
  <div class="block block-rounded">
    <div class="block-header block-header-default">
      <h3 v-if="job.id !== null" class="block-title">
        Update Job - {{ job.id }} -
        <span v-if="job.customer.name">for {{ job.customer.name }}</span>
      </h3>
    </div>
    <div>
      <form id="job.customer" method="POST" @submit.prevent="saveJob">
        <div v-if="formFields.id !== null">
          <input type="hidden" name="_method" value="PUT" />
        </div>
        <div class="block-content block-content-full">
          <div class="row push">
            <div class="col-lg-4">
              <div class="fs-sm text text-muted">
                <h5>Customer Details</h5>
                <p v-if="props.job.customer.name">
                  <strong>{{ job.customer.name }}</strong>
                  <br />
                  <span v-if="props.job.customer.office_address">
                    <span v-if="props.job.customer.office_address.line_1">
                      {{ job.customer.office_address.line_1 }}
                    </span>
                    <span v-if="props.job.customer.office_address.line_2">
                      , {{ job.customer.office_address.line_2 }}
                    </span>
                    <span v-if="props.job.customer.office_address.city">
                      , {{ job.customer.office_address.city }}
                    </span>
                    <span v-if="props.job.customer.office_address.postcode">
                      , {{ job.customer.office_address.postcode }}
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="fs-sm text text-muted">
                <h5 class="mb-1">Site Address</h5>
                <span v-if="props.job.address.line_1">
                  {{ props.job.address.line_1 }},
                </span>
                <span v-if="props.job.address.line_2">
                  {{ props.job.address.line_2 }},
                </span>
                <span v-if="props.job.address.city">
                  {{ props.job.address.city }},
                </span>
                <span v-if="props.job.address.postcode">
                  {{ props.job.address.postcode }}
                </span>
              </div>
              <div class="fs-sm text text-muted">
                <h5 class="mb-1">Contact</h5>
                <span v-if="props.job.quote.contact.name">
                  <strong>Name :</strong>
                  {{ props.job.quote.contact.name }}
                  <br />
                </span>
                <span v-if="props.job.quote.contact.email">
                  <strong>Email :</strong>
                  {{ props.job.quote.contact.email }}
                  <br />
                </span>
                <span v-if="props.job.quote.contact.phone">
                  <strong>Phone :</strong>
                  {{ props.job.quote.contact.phone }}
                </span>
              </div>
            </div>
            <div v-if="formFields.id" class="col-lg-4">
              <div class="fs-sm text text-muted">
                <h5>Quote Details</h5>
                <span v-if="props.job.status">
                  <strong>Status:</strong>
                  {{ formFields.status }}
                  <br />
                </span>
                <span v-if="props.job.id">
                  <strong>Quote Number:</strong>
                  {{ job.id }}
                </span>
                <span v-if="props.job.created_at">
                  <br />
                  <strong>Created:</strong>
                  {{ job.created_at }}
                </span>
                <span v-if="props.job.updated_at">
                  <br />
                  <strong>Updated:</strong>
                  {{ job.updated_at }}
                </span>
              </div>
              <div class="block mt-3">
                <div class="row">
                  <div v-if="formFields.status !== 'Booking'" class="col">
                    <button
                      type="button"
                      class="btn btn-sm btn-warning"
                      @click="updateStatus('Booking')"
                    >
                      Mark as Booking
                    </button>
                  </div>

                  <div v-if="formFields.status !== 'Booked'" class="col">
                    <button
                      type="button"
                      class="btn btn-sm btn-success"
                      @click="updateStatus('Booked')"
                    >
                      Mark as Booked
                    </button>
                  </div>

                  <div v-if="formFields.status !== 'Canceled'" class="col">
                    <button
                      type="button"
                      class="btn btn-sm btn-danger"
                      @click="updateStatus('Canceled')"
                    >
                      Mark as Canceled
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block-content block-content-full">
          <div class="row push">
            <div class="col-12 col-lg-6">
              <input
                id="start_date"
                v-model="formFields.start_date"
                type="text"
                class="form-control js-flatpickers flatpickr-input"
                placeholder="Job Start Date"
                data-date-format="Y-m-d H:i"
              />
            </div>
            <div class="col-12 col-lg-6">
              <input
                id="end_date"
                v-model="formFields.end_date"
                type="text"
                class="form-control js-flatpickers flatpickr-input"
                placeholder="Job End Date"
                data-date-format="Y-m-d H:i"
              />
            </div>

            <div class="row push">
              <div class="col-lg-11"></div>
              <div class="col-1">
                <div class="mb-4 text-right">
                  <button type="submit" class="btn btn-alt-success">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useFetch } from "@/components/useFetch.js";

async function updateStatus(status) {
  try {
    const response = await useFetch(
      route("api.jobs.update-status", formFields.value.id),
      {
        method: "PATCH",
        body: { status },
      }
    );

    if (response.ok) {
      const data = await response.json();
      formFields.value.status = data.status;
    } else {
      const errorData = await response.json();
      alert("Failed to update status: " + errorData);
    }
  } catch (error) {
    alert("An error occurred while updating the status: " + error);
  }
}

async function saveJob() {
  try {
    const response = await useFetch(route("api.jobs.update", [props.job.id]), {
      method: "PUT",
      body: formFields.value,
    });

    if (response.ok) {
      const data = await response.json();

      window.location.href = route("jobs.edit", data.id);
    } else {
      const errorData = await response.json();
      alert("Failed to save quote:" + errorData);
    }
  } catch (error) {
    alert("An error occurred while saving the quote" + error);
  }
}

function formatDate(date) {
  return new Date(date).toISOString().slice(0, 16).replace("T", " ");
}

const props = defineProps({
  route: String,
  job: Object,
});

const formFields = ref({
  id: props.job.id,
  start_date: formatDate(props.job.start_date),
  end_date: formatDate(props.job.end_date),
  status: props.job.status,
});
</script>
