<template>
  <div class="block block-rounded">
    <div class="block-header block-header-default">
      <h3 v-if="formFields.id !== null" class="block-title">
        Update Customer - {{ customer.name }}
      </h3>
      <h3 v-else class="block-title">Create Customer</h3>
    </div>
    <div>
      <form id="customer" method="POST" @submit.prevent="createCustomer">
        <div v-if="formFields.id !== null">
          <input type="hidden" name="_method" value="PUT" />
        </div>
        <div class="block-content block-content-full">
          <div class="row push">
            <div class="col-lg-2">
              <p><strong>Search Companies House</strong></p>
            </div>
            <div class="col-lg-10 col-xl-7">
              <div class="mb-4">
                <div
                  v-if="formFields.id === null"
                  class="card p-4 bg-info-light"
                >
                  <div class="mb-4">
                    <label class="form-label">Company Name</label>
                    <input
                      v-model="companieshousesearch"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div class="mb-4">
                    <div v-if="companyResults.length > 0">
                      <select
                        class="form-select"
                        @change="selectCompany($event.target.value)"
                      >
                        <option value="" selected>Select a company</option>
                        <option
                          v-for="(company, key) in companyResults"
                          :key="key"
                          :value="key"
                        >
                          {{ company.company_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row push">
            <div
              style="
                width: 100%;
                height: 20px;
                border-bottom: 1px solid black;
                text-align: center;
              "
            >
              <span
                style="
                  background-color: #ffffff;
                  padding: 0 10px;
                  font-size: 20px;
                "
                class="text-muted"
              >
                Or Enter Manually
              </span>
            </div>
          </div>
          <div class="row push">
            <div class="col-lg-2">
              <p><strong>Company details</strong></p>
            </div>
            <div class="col-lg-10 col-xl-7">
              <div class="mb-4">
                <label class="form-label" for="name">Name</label>
                <input
                  id="name"
                  v-model="formFields.name"
                  type="text"
                  class="form-control"
                  name="name"
                  required
                />
              </div>
              <div class="mb-4">
                <address-autocomplete
                  @address-selected="handleAddressSelected"
                ></address-autocomplete>
              </div>
              <div class="mb-4">
                <label class="form-label" for="address['line_1']">
                  Address Line 1
                </label>
                <input
                  id="address['line_1']"
                  v-model="formFields.office_address.line_1"
                  type="text"
                  class="form-control"
                  name="office_address['line_1']"
                  required
                />
              </div>
              <div class="mb-4">
                <label class="form-label" for="address['line_2']">
                  Address Line 2
                </label>
                <input
                  id="address['line_2']"
                  v-model="formFields.office_address.line_2"
                  type="text"
                  class="form-control"
                  name="office_address['line_2']"
                />
              </div>
              <div class="mb-4">
                <label class="form-label" for="address['city']">City</label>
                <input
                  id="address['city']"
                  v-model="formFields.office_address.city"
                  type="text"
                  class="form-control"
                  name="office_address['city']"
                />
              </div>
              <div class="mb-4">
                <label class="form-label" for="address['county']">County</label>
                <input
                  id="address['county']"
                  v-model="formFields.office_address.county"
                  type="text"
                  class="form-control"
                  name="office_address['county']"
                />
              </div>
              <div class="mb-4">
                <label class="form-label" for="address['postcode']">
                  Postcode
                </label>
                <input
                  id="address['postcode']"
                  v-model="formFields.office_address.postcode"
                  type="text"
                  class="form-control"
                  name="office_address['postcode']"
                  required
                />
              </div>
              <div class="row">
                <div class="mb-4 col-6">
                  <label class="form-label" for="office_email">Email</label>
                  <input
                    id="office_email"
                    v-model="formFields.office_email"
                    type="email"
                    class="form-control"
                    name="office_email"
                    required
                  />
                </div>
                <div class="mb-4 col-6">
                  <label class="form-label" for="office_phone">Phone</label>
                  <input
                    id="office_phone"
                    v-model="formFields.office_phone"
                    type="text"
                    class="form-control"
                    name="office_phone"
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="mb-4 col-6">
                  <label class="form-label" for="company_number">
                    Company Number
                  </label>
                  <input
                    id="company_number"
                    v-model="formFields.company_number"
                    type="text"
                    class="form-control"
                    name="company_number"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="border-top border-1 row push pt-2"
            style="border-color: #1f2937 !important"
          >
            <div class="col-lg-2">
              <p><strong>Primary Contact Details</strong></p>
            </div>
            <div class="col-lg-8 col-xl-5">
              <div class="mb-4">
                <label class="form-label" for="primary_contact_name">
                  Name
                </label>
                <input
                  id="primary_contact_name"
                  v-model="formFields.primary_contact.name"
                  type="text"
                  class="form-control"
                  name="primary_contact['name']"
                  required
                />
              </div>
              <div class="mb-4">
                <label class="form-label" for="primary_contact_email">
                  Email
                </label>
                <input
                  id="primary_contact_email"
                  v-model="formFields.primary_contact.email"
                  type="email"
                  class="form-control"
                  name="primary_contact['email']"
                  required
                />
              </div>
              <div class="mb-4">
                <label class="form-label" for="primary_contact_phone">
                  Phone
                </label>
                <input
                  id="primary_contact_phone"
                  v-model="formFields.primary_contact.phone"
                  type="text"
                  class="form-control"
                  name="primary_contact['phone']"
                  required
                />
              </div>
            </div>
          </div>
          <div class="row push">
            <div class="col-lg-4"></div>
            <div class="col-lg-8 col-xl-5">
              <div class="mb-4">
                <button type="submit" class="btn btn-alt-success">Save</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import debounce from "lodash/debounce";
import AddressAutocomplete from "@/components/addressAutocomplete.vue";
import { useFetch } from "@/components/useFetch.js";

const props = defineProps({
  route: String,
  customer: {
    type: Object,
    default: () => ({
      id: null,
      name: null,
      office_address: {
        line_1: null,
        line_2: null,
        city: null,
        county: null,
        postcode: null,
      },
      office_email: null,
      office_phone: null,
      company_number: null,
      primary_contact: {
        name: null,
        email: null,
        phone: null,
      },
    }),
  },
});

const companieshousesearch = ref("");
const companyResults = ref([]);
const selectedCompany = ref(null);
const formFields = ref({
  id: props.customer.id ?? null,
  name: props.customer.name ?? null,
  office_address: {
    line_1: props.customer.office_address?.line_1 ?? null,
    line_2: props.customer.office_address?.line_2 ?? null,
    city: props.customer.office_address?.city ?? null,
    county: props.customer.office_address?.county ?? null,
    postcode: props.customer.office_address?.postcode ?? null,
  },
  office_email: props.customer.office_email ?? null,
  office_phone: props.customer.office_phone ?? null,
  company_number: props.customer.company_number ?? null,
  primary_contact: {
    name: props.customer.primary_contact?.name ?? null,
    email: props.customer.primary_contact?.email ?? null,
    phone: props.customer.primary_contact?.phone ?? null,
  },
});

async function fetchCompanies() {
  const response = await useFetch(
    route("api.services.companies-house.search"),
    {
      method: "POST",
      body: { query: companieshousesearch.value },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );

  const data = await response.json();

  companyResults.value = data.items;
}

function selectCompany(key) {
  selectedCompany.value = companyResults.value[key];
  formFields.value.company_number = selectedCompany.value.company_number;
  formFields.value.name = selectedCompany.value.company_name;
  formFields.value.office_address.line_1 =
    selectedCompany.value.registered_office_address.address_line_1;
  formFields.value.office_address.line_2 =
    selectedCompany.value.registered_office_address.address_line_2;
  formFields.value.office_address.city =
    selectedCompany.value.registered_office_address.locality;
  formFields.value.office_address.postcode =
    selectedCompany.value.registered_office_address.postal_code;
}

async function createCustomer() {
  function getCustomerResponse() {
    if (props.customer.id == null) {
      return useFetch(route("api.customers.store"), {
        method: "POST",
        body: JSON.stringify(formFields.value),
        headers: {
          "Content-Type": "application/json",
        },
      });
    } else {
      return useFetch(
        route("api.customers.update", { customer: props.customer.id }),
        {
          method: "PUT",
          body: JSON.stringify(formFields.value),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    }
  }

  const customerResponse = await getCustomerResponse();
  const customerData = await customerResponse.json();

  if (customerResponse.status === 201 || customerResponse.status === 200) {
    window.location.href = route("customers.show", {
      customer: customerData.id,
    });
  } else {
    await Swal.fire({
      icon: "error",
      title: "Error",
      text: "There was an error creating the customer",
    });
  }
}

function handleAddressSelected(address) {
  formFields.value.office_address.line_1 = address.line_1;
  formFields.value.office_address.line_2 = address.line_2;
  formFields.value.office_address.city = address.town_or_city;
  formFields.value.office_address.county = address.county;
  formFields.value.office_address.postcode = address.postcode;
}

watch(companieshousesearch, debounce(fetchCompanies, 500));
</script>
