<template>
  <Modal title="Select Contact" @close="closeModal" @save="selectContact">
    <form @submit.prevent="selectContact">
      <div v-if="existingContacts.length > 0" class="mb-3">
        <label class="form-label">Select Existing Customer Contact</label>
        <select
          v-model="selectedContactReturn"
          class="form-select"
          @change="emitSelectedContact"
        >
          <option
            v-for="contactExisting in existingContacts"
            :key="contactExisting.id"
            :value="contactExisting"
          >
            {{ contactExisting.name }} ({{ contactExisting.email }})
          </option>
        </select>
      </div>
      <div
        v-if="existingContacts.length > 0"
        style="
          width: 100%;
          height: 20px;
          border-bottom: 1px solid black;
          text-align: center;
        "
        class="mb-3"
      >
        <span
          style="background-color: #ffffff; padding: 0 10px; font-size: 20px"
          class="text-muted"
        >
          Or Enter Manually
        </span>
      </div>
      <div class="mb-3">
        <div>
          <label><strong>Name</strong></label>
          <br />
          <input
            v-model="contact.name"
            type="text"
            :class="{ 'is-invalid': errors.name }"
            class="form-control"
            required
          />
          <div v-if="errors.name" class="invalid-feedback">
            {{ errors.name }}
          </div>
        </div>
        <div>
          <label><strong>Email</strong></label>
          <br />
          <input
            v-model="contact.email"
            type="email"
            :class="{ 'is-invalid': errors.email }"
            class="form-control"
            required
          />
          <div v-if="errors.email" class="invalid-feedback">
            {{ errors.email }}
          </div>
        </div>
        <div>
          <label><strong>Phone</strong></label>
          <br />
          <input
            v-model="contact.phone"
            type="text"
            :class="{ 'is-invalid': errors.phone }"
            class="form-control"
          />
          <div v-if="errors.phone" class="invalid-feedback">
            {{ errors.phone }}
          </div>
        </div>
      </div>
    </form>
  </Modal>
</template>

<script setup>
import { ref } from "vue";
import { useFetch } from "@/components/useFetch.js";
import Modal from "@/components/modal.vue";

const props = defineProps({
  customer: {
    type: Object,
    default: () => ({
      id: null,
      name: null,
    }),
    required: false,
  },
  selectedContact: {
    type: Object,
    default: () => ({
      id: null,
      name: null,
    }),
    required: false,
  },
});

const contact = ref({
  id: null,
  name: null,
});

const existingContacts = ref([]);

const selectedContactReturn = ref({
  id: null,
  name: null,
});

const errors = ref({});

if (props.selectedContact.id) {
  selectedContactReturn.value = props.selectedContact;
}

async function fetchExistingContacts() {
  const response = await useFetch(
    route("api.customers.contacts", { customer: props.customer.id })
  );
  existingContacts.value = await response.json();
}

if (props.customer.id) {
  contact.value.customer_id = props.customer.id;
  fetchExistingContacts();
}

async function selectContact() {
  errors.value = {};
  if (selectedContactReturn.value.id === null) {
    const response = await useFetch(
      route("api.customers.contacts.store", {
        customer: props.customer.id,
      }),
      {
        method: "POST",
        body: contact.value,
      }
    );
    const result = await response.json();

    if (response.ok) {
      emit("contact-selected", result);
    } else {
      if (result.errors) {
        for (const key in result.errors) {
          if (Array.isArray(result.errors[key])) {
            errors.value[key] = result.errors[key].join(" ");
          } else {
            errors.value[key] = result.errors[key];
          }
        }
      } else {
        alert("Failed to save contact");
      }
    }
  } else {
    emit("contact-selected", selectedContactReturn.value);
  }
}

const emit = defineEmits(["close", "contact-selected"]);

function closeModal() {
  emit("close");
}
</script>
