<template>
  <div class="row mb-4">
    <div class="col-md-2">
      <select v-model="limit" class="form-select">
        <option>10</option>
        <option>25</option>
        <option>50</option>
      </select>
    </div>
    <div class="col-md-4 ms-auto">
      <div class="input-group">
        <input
          v-model="searchTerm"
          type="text"
          class="form-control"
          placeholder="Search quotes.."
        />
        <span class="input-group-text bg-body border-0">
          <i class="fa fa-search"></i>
        </span>
      </div>
    </div>
  </div>

  <datatable
    :url="ajaxUrl"
    :limit="limit"
    :order-by="orderBy"
    :order-asc="orderAsc"
    :search="searchTerm"
  >
    <template #headings>
      <sortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="reference"
        @set-order-by="setOrder"
      >
        Number
      </sortableTh>
      <th>Customer</th>
      <th>Contact</th>
      <sortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="status"
        @set-order-by="setOrder"
      >
        Status
      </sortableTh>
      <th>Site Address</th>
      <th>Value</th>
      <sortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="created_at"
        @set-order-by="setOrder"
      >
        Created
      </sortableTh>
      <th></th>
    </template>
    <template #rows="{ data }">
      <tr v-for="quote in data" :key="quote.id">
        <td>{{ quote.reference }}</td>
        <td>{{ quote.customer_name }}</td>
        <td>{{ quote.contact_name }}</td>
        <td>
          <span :class="quote.status_class">{{ quote.status }}</span>
        </td>
        <td>{{ quote.address }}</td>
        <td>{{ quote.total_price }}</td>
        <td>{{ quote.created }}</td>
        <td v-html="quote.actions"></td>
      </tr>
    </template>
  </datatable>
</template>

<script setup>
import { ref } from "vue";
import datatable from "@/components/datatable.vue";
import sortableTh from "@/components/datatable/sortableTh.vue";

const props = defineProps({
  ajaxUrl: {
    type: String,
    required: true,
  },
});

const orderBy = ref("created_at");
const orderAsc = ref(false);
const limit = ref(25);
const searchTerm = ref(null);

const setOrder = function (input) {
  if (orderBy.value == input) {
    orderAsc.value = !orderAsc.value;
  } else {
    orderBy.value = input;
    orderAsc.value = true;
  }
};
</script>
