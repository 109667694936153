<template>
  <Modal title="Select Site Address" @close="closeModal" @save="selectAddress">
    <form @submit.prevent="selectAddress">
      <div v-if="existingAddresses.length > 0" class="mb-3">
        <label class="form-label">Select Existing Customer Site Address</label>
        <select
          v-model="selectedAddressReturn"
          class="form-select"
          @change="emitSelectedAddress"
        >
          <option
            v-for="address in existingAddresses"
            :key="address.id"
            :value="address"
          >
            {{ address.line_1 }}, {{ address.city }},
            {{ address.postcode }}
          </option>
        </select>
      </div>
      <div
        v-if="existingAddresses.length > 0"
        style="
          width: 100%;
          height: 20px;
          border-bottom: 1px solid black;
          text-align: center;
        "
        class="mb-3"
      >
        <span
          style="background-color: #ffffff; padding: 0 10px; font-size: 20px"
          class="text-muted"
        >
          Or Enter Manually
        </span>
      </div>
      <address-autocomplete
        @addressSelected="handleAddressSelected"
      ></address-autocomplete>
      <div class="mb-3">
        <div>
          <label><strong>Address Line 1</strong></label>
          <br />
          <input
            v-model="siteAddress.line_1"
            type="text"
            :class="{ 'is-invalid': errors.line_1 }"
            class="form-control"
            required
          />
          <div v-if="errors.line_1" class="invalid-feedback">
            {{ errors.line_1 }}
          </div>
        </div>
        <div>
          <label><strong>Address Line 2</strong></label>
          <br />
          <input
            v-model="siteAddress.line_2"
            type="text"
            :class="{ 'is-invalid': errors.line_2 }"
            class="form-control"
          />
          <div v-if="errors.line_2" class="invalid-feedback">
            {{ errors.line_2 }}
          </div>
        </div>
        <div>
          <label><strong>City</strong></label>
          <br />
          <input
            v-model="siteAddress.city"
            type="text"
            :class="{ 'is-invalid': errors.city }"
            class="form-control"
            required
          />
          <div v-if="errors.city" class="invalid-feedback">
            {{ errors.city }}
          </div>
        </div>
        <div>
          <label><strong>Postcode</strong></label>
          <br />
          <input
            v-model="siteAddress.postcode"
            type="text"
            :class="{ 'is-invalid': errors.postcode }"
            class="form-control"
            required
          />
          <div v-if="errors.postcode" class="invalid-feedback">
            {{ errors.postcode }}
          </div>
        </div>
      </div>
      <input v-model="siteAddress.coords.longitude" type="hidden" />
      <input v-model="siteAddress.coords.latitude" type="hidden" />
    </form>
  </Modal>
</template>

<script setup>
import { ref } from "vue";
import AddressAutocomplete from "@/components/addressAutocomplete.vue";
import { useFetch } from "@/components/useFetch.js";
import Modal from "@/components/modal.vue";

const props = defineProps({
  customer: {
    type: Object,
    default: () => ({
      id: null,
      name: null,
    }),
  },
  selectedAddress: {
    type: Object,
    default: () => ({
      id: null,
      line_1: null,
      line_2: null,
      city: null,
      postcode: null,
      coords: {
        longitude: null,
        latitude: null,
      },
    }),
  },
});

const siteAddress = ref({
  id: null,
  line_1: null,
  line_2: null,
  city: null,
  postcode: null,
  coords: {
    longitude: null,
    latitude: null,
  },
});

function handleAddressSelected(address) {
  siteAddress.value.line_1 = address.line_1;
  siteAddress.value.line_2 = address.line_2;
  siteAddress.value.city = address.town_or_city;
  siteAddress.value.postcode = address.postcode;
  siteAddress.value.coords.longitude = address.longitude;
  siteAddress.value.coords.latitude = address.latitude;
}

const existingAddresses = ref([]);

const selectedAddressReturn = ref({
  id: null,
  line_1: null,
  line_2: null,
  city: null,
  postcode: null,
  coords: {
    longitude: null,
    latitude: null,
  },
});

const errors = ref({});

if (props.selectedAddress.id) {
  selectedAddressReturn.value = props.selectedAddress;
}

async function fetchExistingAddresses() {
  const response = await useFetch(
    route("api.customers.site-addresses", { customer: props.customer.id })
  );
  existingAddresses.value = await response.json();
}

if (props.customer.id) {
  siteAddress.value.customer_id = props.customer.id;
  fetchExistingAddresses();
}

async function selectAddress() {
  errors.value = {};
  if (selectedAddressReturn.value.id === null) {
    const response = await useFetch(
      route("api.customers.site-addresses.store", {
        customer: props.customer.id,
      }),
      {
        method: "POST",
        body: siteAddress.value,
      }
    );
    const result = await response.json();

    if (response.ok) {
      emit("address-selected", result);
    } else {
      if (result.errors) {
        for (const key in result.errors) {
          if (Array.isArray(result.errors[key])) {
            errors.value[key] = result.errors[key].join(" ");
          } else {
            errors.value[key] = result.errors[key];
          }
        }
      } else {
        alert("Failed to save address");
      }
    }
  } else {
    emit("address-selected", selectedAddressReturn.value);
  }
}

const emit = defineEmits(["close", "address-selected"]);

function closeModal() {
  emit("close");
}
</script>
