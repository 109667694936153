<template>
  <div>
    <label class="form-label" :for="id">{{ label }}</label>
    <input
      :id="id"
      v-model="addressSearch"
      type="text"
      :name="name"
      class="form-control"
      placeholder="Enter an address to search for..."
    />
    <div v-if="error" class="text-danger">
      Error fetching address data: {{ error.message }}
    </div>
    <ul v-if="suggestions.length" class="list-group mt-2">
      <li
        v-for="suggestion in suggestions"
        :key="suggestion.id"
        class="list-group-item list-group-item-action"
        @click="selectAddress(suggestion)"
      >
        {{ suggestion.address }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useFetch } from "@/components/useFetch.js";

const props = defineProps({
  label: {
    type: String,
    default: "Find an Address",
  },
  name: {
    type: String,
    default: "address",
  },
  id: {
    type: String,
    default: "address",
  },
});

const addressSearch = ref("");
const suggestions = ref([]);
const selectedAddress = ref([]);
const error = ref(null);
const emit = defineEmits(["addressSelected"]);

async function getAddress(query) {
  try {
    const response = await useFetch(
      route("api.services.get-address.autocomplete", { query })
    );
    const data = await response.json();
    suggestions.value = data.suggestions || [];
  } catch (err) {
    error.value = err;
  }
}

async function selectAddress(address) {
  try {
    const response = await useFetch(
      route("api.services.get-address.from-id", {
        addressId: address.id,
      })
    );
    selectedAddress.value = await response.json();
  } catch (err) {
    error.value = err;
  }
  emit("addressSelected", selectedAddress.value);
}

const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
};

const debouncedGetAddress = debounce(getAddress, 300);

watch(addressSearch, (newQuery) => {
  if (newQuery.length > 2) {
    debouncedGetAddress(newQuery);
  } else {
    suggestions.value = [];
  }
});
</script>
