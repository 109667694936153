<template>
  <SiteAddressModal
    v-if="showSiteAddressModal"
    :selected-address="formFields.address"
    :customer="customer"
    :initial-elevations="currentDetails.elevations"
    @close="closeSiteAddressModal"
    @address-selected="handleAddressSelected"
  />
  <ContactModal
    v-if="showContactModal"
    :selected-address="formFields.contact"
    :customer="customer"
    @close="closeContactModal"
    @contact-selected="handleContactSelected"
  />
  <DetailsModal
    v-if="showDetailsModal"
    :current-data="currentDetails"
    @close="closeDetailsModal"
    @saveDetails="handleDetailsSaved"
  />
  <div class="block block-rounded">
    <div class="block-header block-header-default">
      <h3 v-if="formFields.id !== null" class="block-title">
        Update Quote {{ formFields.reference }}
        <span v-if="customer.name">for {{ customer.name }}</span>
      </h3>
      <h3 v-else class="block-title">
        Create Quote
        <span v-if="customer.name">for {{ customer.name }}</span>
      </h3>

      <button
        v-if="formFields.id !== null"
        id="dropdown-email-quote"
        type="button"
        class="btn btn-sm btn-primary dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Email Quote
      </button>
      <div
        v-if="formFields.id !== null"
        class="dropdown-menu fs-sm"
        aria-labelledby="dropdown-email-quote"
      >
        <button
          type="button"
          class="dropdown-item"
          @click="sendViaEmail('Link', 'Detailed')"
        >
          Online Approval
        </button>
        <div class="dropdown-divider"></div>
        <button
          type="button"
          class="dropdown-item"
          @click="sendViaEmail('Attachment', 'Summary')"
        >
          With Summary Attachment
        </button>
        <button
          type="button"
          class="dropdown-item"
          @click="sendViaEmail('Attachment', 'Detailed')"
        >
          With Detailed Attachment
        </button>
      </div>
    </div>
    <div>
      <form id="customer" method="POST" @submit.prevent="saveQuote">
        <div v-if="formFields.id !== null">
          <input type="hidden" name="_method" value="PUT" />
        </div>
        <div class="block-content block-content-full">
          <div class="row push">
            <div class="col-lg-4">
              <div class="fs-sm text text-muted">
                <h5>Customer Details</h5>
                <p v-if="customer.name">
                  <strong>{{ customer.name }}</strong>
                  <br />
                  <span v-if="customer.office_address">
                    <span v-if="customer.office_address.line_1">
                      {{ customer.office_address.line_1 }}
                    </span>
                    <span v-if="customer.office_address.line_2">
                      , {{ customer.office_address.line_2 }}
                    </span>
                    <span v-if="customer.office_address.city">
                      , {{ customer.office_address.city }}
                    </span>
                    <span v-if="customer.office_address.postcode">
                      , {{ customer.office_address.postcode }}
                    </span>
                  </span>
                </p>

                <div v-if="!props.customer.id" class="block mt-1">
                  <button
                    type="button"
                    class="btn btn-sm btn-alt-primary"
                    @click="showCustomerModal = true"
                  >
                    Select Site Address
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="fs-sm text text-muted">
                <h5 class="mb-1">Site Address</h5>
                <span v-if="formFields.address.line_1">
                  {{ formFields.address.line_1 }},
                </span>
                <span v-if="formFields.address.line_2">
                  {{ formFields.address.line_2 }},
                </span>
                <span v-if="formFields.address.city">
                  {{ formFields.address.city }},
                </span>
                <span v-if="formFields.address.postcode">
                  {{ formFields.address.postcode }}
                </span>

                <div class="block mt-2">
                  <button
                    type="button"
                    class="btn btn-sm btn-alt-primary"
                    @click="toggleSiteAddressModal()"
                  >
                    Select Site Address
                  </button>
                </div>
              </div>
              <div class="fs-sm text text-muted">
                <h5 class="mb-1">Contact</h5>
                <span v-if="formFields.contact.name">
                  <strong>Name :</strong>
                  {{ formFields.contact.name }}
                  <br />
                </span>
                <span v-if="formFields.contact.email">
                  <strong>Email :</strong>
                  {{ formFields.contact.email }}
                  <br />
                </span>
                <span v-if="formFields.contact.phone">
                  <strong>Phone :</strong>
                  {{ formFields.contact.phone }}
                </span>
                <div class="block mt-3">
                  <button
                    type="button"
                    class="btn btn-sm btn-alt-primary"
                    @click="toggleContactModal()"
                  >
                    Select Contact
                  </button>
                </div>
              </div>
            </div>
            <div v-if="formFields.id" class="col-lg-4">
              <div class="fs-sm text text-muted">
                <h5>Quote Details</h5>
                <span v-if="props.quote.status">
                  <strong>Status:</strong>
                  <span
                    :class="[
                      statusClasses[formFields.status],
                      'mx-2 rounded-pill',
                    ]"
                    style="font-size: 0.85rem"
                  >
                    <strong>{{ formFields.status }}</strong>
                  </span>
                  <br />
                </span>
                <span
                  v-if="
                    formFields.status === 'Rejected' &&
                    formFields.rejection_reason
                  "
                >
                  <strong>Rejection Reason:</strong>
                  {{ formFields.rejection_reason }}
                  <br />
                </span>
                <span v-if="formFields.id">
                  <strong>Quote Reference:</strong>
                  {{ formFields.reference }}
                </span>
                <span v-if="formFields.created_at">
                  <br />
                  <strong>Created:</strong>
                  {{ formFields.created_at }}
                </span>
                <span v-if="formFields.updated_at">
                  <br />
                  <strong>Updated:</strong>
                  {{ formFields.updated_at }}
                </span>
                <span>
                  <br />
                  <strong>Quote link:</strong>
                  <a :href="props.publicLink" target="_blank">
                    Public Quote Link
                  </a>
                </span>
              </div>
              <div class="block mt-3">
                <div v-if="formFields.status !== 'Converted'" class="row">
                  <div v-if="formFields.status !== 'Accepted'" class="col">
                    <button
                      type="button"
                      class="btn btn-sm btn-success"
                      @click="updateStatus('Accepted')"
                    >
                      Mark as Accepted
                    </button>
                  </div>
                  <div v-if="formFields.status === 'Accepted'" class="col">
                    <button
                      type="button"
                      class="btn btn-sm btn-success"
                      @click="convertToJob()"
                    >
                      Convert To Job
                    </button>
                  </div>
                  <div
                    v-if="
                      formFields.status !== 'Rejected' &&
                      formFields.status !== 'Created'
                    "
                    class="col"
                  >
                    <button
                      type="button"
                      class="btn btn-sm btn-danger"
                      @click="updateStatus('Rejected')"
                    >
                      Mark as Rejected
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block-content block-content-full">
          <div class="row push">
            <div class="col-lg-12 col-xl-12">
              <div class="mb-4">
                <table class="table table-striped w-full">
                  <thead>
                    <tr>
                      <th class="col-4">Description</th>
                      <th>Initial Hire Weeks</th>
                      <th>Initial Hire Price</th>
                      <th>Overhire</th>
                      <th>VAT Rate</th>
                    </tr>
                  </thead>
                  <tbody v-if="formFields.items.length > 0">
                    <tr v-for="(item, index) in formFields.items" :key="index">
                      <td>
                        <input
                          v-model="item.description"
                          type="text"
                          class="form-control"
                          name="items[{{ index }}][description]"
                          required
                        />
                      </td>
                      <td>
                        <input
                          v-model="item.initial_hire_weeks"
                          type="number"
                          class="form-control"
                          name="items[{{ index }}][initial_hire_weeks]"
                          required
                        />
                      </td>
                      <td>
                        <div class="input-group">
                          <span class="input-group-text">£</span>
                          <input
                            v-model="item.initial_hire_price"
                            type="number"
                            class="form-control"
                            name="items[{{ index }}][initial_hire_price]"
                            required
                          />
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <span class="input-group-text">£</span>
                          <input
                            v-model="item.overhire_rate"
                            type="number"
                            class="form-control"
                            name="items[{{ index }}][overhire_rate]"
                            required
                          />
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <input
                            v-model="item.vat_rate"
                            type="number"
                            class="form-control"
                            name="items[{{ index }}][vat_rate]"
                            required
                          />
                          <span class="input-group-text">%</span>
                        </div>
                      </td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-sm btn-alt-primary"
                          @click="toggleDetailsModal(index)"
                        >
                          Details
                        </button>
                      </td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-sm btn-alt-danger"
                          @click="confirmRemoveItem(index)"
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="7" class="text-center">
                        <button
                          type="button"
                          class="btn btn-sm btn-alt-primary"
                          @click="addItem"
                        >
                          Add Line
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <td>
                        <strong>Total Hire Cost</strong>
                      </td>
                      <td>
                        <strong>{{ formatCurrency(totalHireCost) }}</strong>
                      </td>

                      <td>
                        <strong>Total VAT Cost</strong>
                      </td>
                      <td>
                        <strong>{{ formatCurrency(totalVatCost) }}</strong>
                      </td>
                      <td>
                        <strong>Total Cost Including VAT</strong>
                      </td>
                      <td>
                        <strong>{{ formatCurrency(totalCost) }}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row push">
              <div class="col-lg-11"></div>
              <div class="col-1">
                <div class="mb-4 text-right">
                  <button type="submit" class="btn btn-alt-success">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useFetch } from "@/components/useFetch.js";
import SiteAddressModal from "@/components/quotes/modals/siteaddress.vue";
import ContactModal from "@/components/quotes/modals/contact.vue";
import DetailsModal from "@/components/quotes/modals/detailsModal.vue";

const showSiteAddressModal = ref(false);
const showContactModal = ref(false);
const showDetailsModal = ref(false);
const currentDetails = ref([
  {
    elevations: [],
    details: [],
    uploads: [],
    item_id: null,
    quote_id: null,
    temp_hash: null,
  },
]);
const currentItemIndex = ref(null);

function toggleDetailsModal(index) {
  currentItemIndex.value = index;
  currentDetails.value = {
    elevations: formFields.value.items[index].elevations,
    paragraphs: formFields.value.items[index].details,
    uploads: formFields.value.items[index].uploads,
    item_id: formFields.value.items[index].id,
    quote_id: formFields.value.id,
    temp_hash: formFields.value.items[index].temp_hash,
  };
  showDetailsModal.value = !showDetailsModal.value;
}

function closeDetailsModal() {
  showDetailsModal.value = false;
}

function handleDetailsSaved(details) {
  if (currentItemIndex.value !== null) {
    formFields.value.items[currentItemIndex.value].details = details.paragraphs;
    formFields.value.items[currentItemIndex.value].elevations =
      details.elevations;
    formFields.value.items[currentItemIndex.value].uploads = details.uploads;
  }
  showDetailsModal.value = false;
}

// Function to toggle the modal visibility
function toggleSiteAddressModal() {
  showSiteAddressModal.value = !showSiteAddressModal.value;
}

// Function to close the modal
function closeSiteAddressModal() {
  showSiteAddressModal.value = false;
}

function toggleContactModal() {
  showContactModal.value = !showContactModal.value;
}

// Function to close the modal
function closeContactModal() {
  showContactModal.value = false;
}

// Function to handle the address-selected event
function handleAddressSelected(address) {
  formFields.value.address = address; // Update the formFields with the selected address
  formFields.value.address_id = address.id; // Set the site address id
  showSiteAddressModal.value = false; // Close the modal
}

function handleContactSelected(contact) {
  formFields.value.contact = contact; // Update the formFields with the selected contact
  formFields.value.contact_id = contact.id; // Set the contact id
  showContactModal.value = false; // Close the modal
}

async function updateStatus(status) {
  try {
    const response = await useFetch(
      route("api.quotes.update-status", formFields.value.id),
      {
        method: "PATCH",
        body: { status },
      }
    );

    if (response.ok) {
      const data = await response.json();
      formFields.value.status = data.status;
    } else {
      const errorData = await response.json();
      alert("Failed to update status: " + errorData);
    }
  } catch (error) {
    alert("An error occurred while updating the status: " + error);
  }
}

async function sendViaEmail(emailType, quoteType) {
  try {
    const response = await useFetch(
      route("api.quotes.email", formFields.value.id),
      {
        method: "POST",
        body: { emailType, quoteType },
      }
    );

    if (response.ok) {
      alert("Email sent successfully");
    } else {
      const errorData = await response.json();
      alert("Failed to send email: " + errorData);
    }
  } catch (error) {
    alert("An error occurred while updating the status: " + error);
  }
}

async function convertToJob() {
  try {
    const response = await useFetch(
      route("api.quotes.convert", formFields.value.id),
      {
        method: "POST",
      }
    );

    if (response.ok) {
      const data = await response.json();
      formFields.value.status = data.status;
    } else {
      const errorData = await response.json();
      alert("Failed to convert to job: " + errorData);
    }
  } catch (error) {
    alert("An error occurred whilst converting this quote to a job: " + error);
  }
}

const props = defineProps({
  route: String,
  quote: {
    type: Object,
    required: true,
  },
  customer: {
    type: Object,
    required: true,
  },
  publicLink: {
    type: String,
    default: null,
  },
  statusClasses: {
    type: Object,
    default: () => ({
      Created: "text-warning",
      Accepted: "text-success",
      Rejected: "text-danger",
      Converted: "text-primary",
      Sent: "text-info",
      "Customer Viewed": "text-info",
    }),
  },
});

const formFields = ref({
  id: props.quote.id ?? null,
  reference: props.quote.reference ?? null,
  status: props.quote.status ?? null,
  rejection_reason: props.quote.rejection_reason ?? null,
  customer_id: props.customer.id ?? null,
  items: props.quote.items ?? [],
  address_id: props.quote.address_id ?? null,
  contact_id: props.quote.contact.id ?? null,
  address: {
    line_1: props.quote.address.line_1 ?? null,
    line_2: props.quote.address.line_2 ?? null,
    city: props.quote.address.city ?? null,
    postcode: props.quote.address.postcode ?? null,
  },
  contact: {
    name: props.quote.contact.name ?? null,
    email: props.quote.contact.email ?? null,
    phone: props.quote.contact.phone ?? null,
  },
});

const totalVatCost = ref(0);
const totalHireCost = ref(0);
const totalCost = ref(0);

function addItem() {
  formFields.value.items.push({
    id: null,
    description: null,
    vat_rate: 20,
    initial_hire_weeks: 0,
    initial_hire_price: 0,
    overhire_rate: 0,
    vat_amount: null,
    total_with_vat: null,
    elevations: [],
    details: [],
    uploads: [],
    temp_hash: generateTempHash(),
  });
}

function generateTempHash() {
  return Math.random().toString(36).substring(2, 15);
}

function formatCurrency(value) {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  }).format(value);
}

async function saveQuote() {
  try {
    if (props.quote.id === null) {
      const response = await useFetch(
        route("api.customers.quotes.store", [formFields.value.customer_id]),
        {
          method: "POST",
          body: formFields.value,
        }
      );

      if (response.ok) {
        const data = await response.json();

        // Redirect to the quote page
        window.location.href = route("quotes.edit", data.id);
      } else {
        const errorData = await response.json();
        alert("Failed to save quote:" + errorData);
      }
    } else {
      const response = await useFetch(
        route("api.customers.quotes.update", [
          formFields.value.customer_id,
          props.quote.id,
        ]),
        {
          method: "PUT",
          body: formFields.value,
        }
      );

      if (response.ok) {
        const data = await response.json();

        // Redirect to the quote page
        window.location.href = route("quotes.edit", data.id);
      } else {
        const errorData = await response.json();
        alert("Failed to save quote:" + errorData);
      }
    }
  } catch (error) {
    alert("An error occurred while saving the quote" + error);
  }
}

if (formFields.value.items.length === 0) {
  addItem();
}

if (formFields.value.items.length > 0) {
  // if an item does not have a VAT rate, set it to 20%
  formFields.value.items.forEach((item) => {
    if (!item.vat_rate) {
      item.vat_rate = 20;
    }
  });
}

function confirmRemoveItem(index) {
  if (
    confirm(
      "Are you sure you want to delete this line item and all additional information?"
    )
  ) {
    formFields.value.items.splice(index, 1);
  }
}

function calculateTotals() {
  // Calculate the total for each item
  formFields.value.items.forEach((item) => {
    // make sure the initial hire price is set
    if (!item.initial_hire_price) {
      return;
    }

    // Calculate the total for the item
    item.vat_amount = (item.initial_hire_price * item.vat_rate) / 100;
    item.total_with_vat = item.initial_hire_price + item.vat_amount;
  });

  if (formFields.value.items.length === 0) {
    return;
  }

  // Calculate the total hire cost
  totalHireCost.value = formFields.value.items.reduce((acc, item) => {
    if (!item.initial_hire_price) {
      return acc;
    }
    return acc + item.initial_hire_price;
  }, 0);

  // Calculate the total VAT cost
  totalVatCost.value = formFields.value.items.reduce((acc, item) => {
    if (!item.vat_amount) {
      return acc;
    }
    return acc + item.vat_amount;
  }, 0);

  // Calculate the total cost
  if (totalHireCost.value === 0) {
    totalCost.value = 0;
  } else {
    let total = 0;
    formFields.value.items.forEach((item) => {
      total += item.total_with_vat;
    });
    totalCost.value = total;
  }
}

if (props.quote.items) {
  // loop through each item and set the price to the major value of the array
  if (props.quote.items) {
    formFields.value.items.forEach((item) => {
      item.initial_hire_price = parseInt(
        item.initial_hire_price["major_value"],
        10
      );
      item.overhire_rate = parseInt(item.overhire_rate["major_value"], 10);
    });
  }
  calculateTotals();
}

watch(
  formFields,
  () => {
    calculateTotals();
  },
  { deep: true }
);
</script>
